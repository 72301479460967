import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const StyledTextGallery = styled.section`
  background-color: ${({ theme }) => theme.color.dark};
`

const TextContent = styled(ParseContent)`
  & h2 {
    margin-bottom: 1.5rem;
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.ultra};

    @media (max-width: 991px){
      font-size: ${({ theme }) => theme.font.size.mega};
    }
  }

  & h3 {
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.color.primary};
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.font.size.large};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    letter-spacing: 1.6px;

    @media (max-width: 991px){
      font-size: ${({ theme }) => theme.font.size.large};
    }
  }

  & p {
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.big};
    line-height: 28px;
    font-weight: 300;
  }

  & p:last-child {
    margin-top: 2.5rem;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  @media (min-width: 992px) {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    grid-template-rows: 200px 200px;
  }

  @media (max-width: 991px) {
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    grid-template-rows: 150px 150px;
  }
`

const Col = styled.div`
  &:nth-child(1) {
    grid-column: span 5;
  }

  &:nth-child(2) {
    grid-column: span 7;
  }

  &:nth-child(3) {
    grid-column: span 7;
  }

  &:nth-child(4) {
    grid-column: span 5;
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <StyledTextGallery className="py-lg-5">
    <div className="container py-5">
      <div className="row py-lg-5">
        <div className="col-md-6 pe-md-5">
          <TextContent className="pe-xl-5" content={fields.description} />
        </div>
        <div className="col-md-6 mt-5 mt-md-0">
          <Grid>
            {fields.gallery?.map((media, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <Col key={index}>
                <Plaatjie
                  className="w-100 h-100"
                  image={media}
                  alt="VT Tuinen"
                />
              </Col>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  </StyledTextGallery>
)

export default Text
