import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const TextImage = styled(Plaatjie)`
  width: 550px;
  height: 370px;

  & img {
    width: 550px !important;
    height: 370px !important;
  }
`

const TextContent = styled.div<{ mirror: boolean }>`
  position: relative;
  display: flex;
  align-items: center;

  & h3 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: ${({ theme }) => theme.font.size.huge};
    line-height: 42px;
    color: ${({ theme }) => theme.color.light};
    text-transform: uppercase;
    margin-bottom: 54px;

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: 32px;
    }
  }

  & p:last-child {
    font-size: ${({ theme }) => theme.font.size.large};
    color: ${({ theme }) => theme.color.primary};
    text-align: right;
  }

  @media (min-width: 575px) {
    min-height: 30px;
  }
`

const StyledImageWithText = styled.div`
  background-color: ${({ theme }) => theme.color.grey};

  @media (min-width: 992px) {
    height: 320px;
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <section>
    <StyledImageWithText className="d-flex align-items-center">
      <div className="container">
        <div className="row align-items-lg-center">
          <div
            className={`col-md-6 position-relative px-lg-0 ${
              fields?.mirror && 'text-end'
            } order-lg-${fields.mirror ? '3' : '1'}`}
          >
            <TextImage image={fields.image} alt="VT Tuinen" />
          </div>
          <TextContent
            mirror={!!fields.mirror}
            className="col-md-6 py-md-5 ps-4 order-lg-1"
          >
            <ParseContent
              className="py-5 mt-sm-0 mt-lg-4 py-md-4"
              content={fields.description}
            />
          </TextContent>
        </div>
      </div>
    </StyledImageWithText>
  </section>
)

export default Text
