import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import parse from 'html-react-parser'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

const StyledText = styled.section`
  background-color: ${({ theme }) => theme.color.dark};
`

const TextContent = styled(ParseContent)`
  h2 {
    margin-bottom: 2rem;
    color: ${({ theme }) => theme.color.light};
  }

  h3 {
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.color.primary};
    font-size: ${({ theme }) => theme.font.size.large};
    text-transform: uppercase;
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    letter-spacing: 1.6px;
  }

  & p {
    color: ${({ theme }) => theme.color.light};
  }

  & p:last-child {
    margin-top: 2rem;
  }
`

const TextBlock = styled(Link)`
  display: flex;
  align-items: flex-end;
  border: 1px solid ${({ theme }) => theme.color.light};
  box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2);

  & > div {
    padding: 28px;

    @media (max-width: 1399px) {
      padding: 14px;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.light};
    & h3 {
      text-decoration: none !important;
      color: ${({ theme }) => theme.color.dark};
    }
  }

  @media (min-width: 992px) {
    height: 195px;
  }

  @media (max-width: 575px) {
    height: 150px;
  }
`

const TextBlockTitle = styled.h3`
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.light};

  @media (min-width: 1200px) {
    font-size: ${({ theme }) => theme.font.size.large};
  }

  @media (max-width: 1199px) {
    font-size: ${({ theme }) => theme.font.size.medium};
    word-break: break-word;
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <StyledText className="py-lg-5">
    <div className="container py-5">
      <div className="row justify-content-between py-lg-5">
        <div className="col-md-6 pe-lg-5">
          <TextContent className="pe-lg-5" content={fields.description} />
        </div>
        <div className="col-md-5 mt-5 mt-lg-0">
          <div className="row">
            {fields.icons?.map((edge, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className="col-6 col-sm-12 col-lg-6 mb-4">
                <TextBlock to={edge?.link?.url || ''}>
                  <div className="w-100">
                    <Plaatjie
                      image={edge?.icon}
                      alt={edge?.link?.title || ''}
                    />
                    <TextBlockTitle className="mt-3">
                      {parse(edge?.link?.title || '')}
                    </TextBlockTitle>
                  </div>
                </TextBlock>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </StyledText>
)

export default Text
