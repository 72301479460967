import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import styled from 'styled-components'

const StyledText = styled.section`
  background-color: ${({ theme }) => theme.color.dark};
`

const Content = styled(ParseContent)`
  & h3 {
    font-size: ${({ theme }) => theme.font.size.large};
    font-weight: ${({ theme }) => theme.font.weight.semiBold};
    color: ${({ theme }) => theme.color.primary};
    text-transform: uppercase;
    margin-bottom: 1rem;
    letter-spacing: 1.6px;

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.large};
    }
  }

  & h2 {
    margin-bottom: 2rem;
    font-size: ${({ theme }) => theme.font.size.ultra};
    color: ${({ theme }) => theme.color.light};

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: 1;
    }
  }

  & p {
    font-size: ${({ theme }) => theme.font.size.big};
    font-weight: ${({ theme }) => theme.font.weight.light};
    color: ${({ theme }) => theme.color.light};
    line-height: 29px;

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.medium};
      line-heigh: 25px;
    }
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <StyledText className="py-lg-5">
    <div className="container py-5">
      <div className="row">
        <div className={`col-sm-5 col-lg-${fields.widthcolumnleft}`}>
          <Content content={fields?.descriptionleft} />
        </div>
        <div
          className={`col-sm-7 col-lg-${
            12 - (fields.widthcolumnleft || 4)
          } mt-4 mt-sm-0`}
        >
          <Content content={fields?.description} className="pe-lg-5" />
        </div>
      </div>
    </div>
  </StyledText>
)

export default Text
