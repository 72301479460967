import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'
import styled from 'styled-components'

const StyledText = styled.section`
  background-color: ${({ theme }) => theme.color.dark};
`

const Content = styled(ParseContent)`
  & h2,
  & p,
  & li {
    color: ${({ theme }) => theme.color.light};
  }
`

interface TextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const Text: React.FC<TextProps> = ({ fields }) => (
  <StyledText>
    <div className="container py-5">
      <div className="row py-lg-5">
        <Content content={fields.description} />
      </div>
    </div>
  </StyledText>
)

export default Text
